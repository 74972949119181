import React from "react"
import PropTypes from "prop-types"
import Breadcrumbs from "./ui/breadcrumbs"
import { pathParts, breadcrumbPathGenerator } from "../util/helpers"

export const IndexHeader = ({ children, breadcrumbs }) => {
  return (
    <header className="pb-3 mb-5">
      {breadcrumbs.length > 0 && <Breadcrumbs links={breadcrumbs} />}
      <h1 className="font-bold font-serif leading-tight text-4xl md:text-5xl text-secondary-500 capitalize">
        {children}
      </h1>
    </header>
  )
}

IndexHeader.propTypes = {
  children: PropTypes.node.isRequired,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.array])
  ),
}

IndexHeader.defaultProps = {
  breadcrumbs: [],
}

export const PageHeader = props => {
  const { children, breadcrumbs, metadata } = props
  return (
    <header className="pb-3 mb-5">
      {breadcrumbs.length > 0 && <Breadcrumbs links={breadcrumbs} />}
      <h1 className="font-bold font-serif leading-tight text-4xl md:text-6xl text-primary-500">
        {children}
      </h1>
      {metadata && <div className="mt-5 text-gray-600">{metadata}</div>}
    </header>
  )
}

PageHeader.propTypes = {
  children: PropTypes.node.isRequired,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.array])
  ),
  metadata: PropTypes.string,
}

PageHeader.defaultProps = {
  breadcrumbs: [],
}
