import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

const linkObjectCreator = value => {
  let name, href
  if (typeof value === "string") {
    name = value
    href = `/${value}`
  } else if (typeof value === "object") {
    if (Array.isArray(value)) {
      ;[name, href] = value
    }
  }
  return {
    name,
    href,
  }
}
const Breadcrumbs = props => {
  const { links = [] } = props

  return (
    <nav className="font-bold leading-tight text-sm">
      <ul className="flex">
        {links.map((value, i) => {
          const { name, href } = linkObjectCreator(value)
          return (
            <li key={i}>
              <Link
                to={href}
                className="text-orange-400 hover:text-primary-500 capitalize"
              >
                {name}
              </Link>
              <span className="text-gray-400 mx-1">/</span>
            </li>
          )
        })}
      </ul>
    </nav>
  )
}

export default Breadcrumbs
