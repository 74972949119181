import React from "react"
import PropTypes from "prop-types"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { IndexHeader } from "../components/header"
import { pathParts, breadcrumbPathGenerator } from "../util/helpers"

const Tech = ({ pageContext, data, path }) => {
  const { isProject } = pageContext
  const { blog, project } = data

  let edges
  if (isProject) edges = project.edges
  else edges = blog.edges
  const parts = pathParts(path)
  const technology = parts.pop()

  return (
    <Layout>
      <SEO
        title={technology}
        description={`${technology} projects and/or blog posts. Super focused. Super good. Just ${technology} and nothing else.`}
        path={path}
        keywords={[technology, "technology", "programming", "coding"]}
      />
      <section className="max-w-3xl px-5 mx-auto">
        <IndexHeader breadcrumbs={breadcrumbPathGenerator(parts)}>
          {technology}
        </IndexHeader>
        <ul className="divide-gray-300 divide-y">
          {edges.map(({ node }) => {
            const { title, date, path } = node.frontmatter
            return (
              <li key={node.id} className="pb-10">
                <Link to={path} className="no-underline group">
                  <div className="mb-3">
                    <div className="mb-1">
                      <h3 className="font-extrabold group-hover:text-highlight-yellow inline leading-tight text-3xl text-primary-500">
                        {title}
                      </h3>
                    </div>
                    <div>
                      <h5 className="inline text-gray-600">{date}</h5>
                    </div>
                  </div>
                  <p className="text-gray-700">{node.excerpt}</p>
                </Link>
              </li>
            )
          })}
        </ul>
      </section>
    </Layout>
  )
}

Tech.propTypes = {
  pageContext: PropTypes.shape({
    technology: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              title: PropTypes.string.isRequired,
            }),
            fields: PropTypes.shape({
              slug: PropTypes.string.isRequired,
            }),
          }),
        }).isRequired
      ),
    }),
  }),
}

export default Tech

export const techPageQuery = graphql`
  query TechnologyPage($technology: String, $isProject: Boolean = false) {
    blog: allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: {
          draft: { eq: false }
          technologies: { elemMatch: { id: { in: [$technology] } } }
        }
      }
    ) @skip(if: $isProject) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
            rawDate: date
            path
          }
          excerpt
        }
      }
    }
    project: allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: {
          draft: { eq: false }
          project: {
            technologies: { elemMatch: { id: { in: [$technology] } } }
          }
        }
      }
    ) @include(if: $isProject) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
            rawDate: date
            path
          }
          excerpt
        }
      }
    }
  }
`
